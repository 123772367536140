import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-native';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { Else, If, Then } from '@common/components/If';
import { JobPostingNotAvailable } from '@common/components/JobPostingNotAvailable';
import { useLimitJobRolesEnabled } from '@common/hooks/featureToggles';
import { useRedirectToJobNotFoundIfError } from '@common/hooks/useRedirectToJobNotFoundIfError';
import {
  useShowJobLimitReached,
  useShowOverBusinessLimitModal
} from '@common/hooks/userPackages';
import { SupportLevel } from '@seek/je-shared-data/lib/types/brand';
import { loadDashboardJobs } from '@store/entities/jobs';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Stack } from '@components/Stack';
import { JobLimitReached } from '../job-limit-reached';
import { UpdateYourBusinessesModal } from '../post-job/components';
import { FormCard } from './FormCard';
import { Header } from './Header';
import { RepostFormFetchFromOriginalJob } from './RepostFormFetchFromOriginalJob';
import { RepostFormUseExistingDraft } from './RepostFormUseExistingDraft';
import { useRedirectToLoginPageIfUnauthenticated } from './hooks/useRedirectToLoginPageIfUnauthenticated';

export const RepostJob = () => {
  useRedirectToJobNotFoundIfError();
  useRedirectToLoginPageIfUnauthenticated();
  const dispatch = useAppDispatch();
  const alreadyLoadedOriginalJob = useLimitJobRolesEnabled();
  const { supportTier } = useAppSelector(getLocalisation);
  const { originalJobId } = useParams<{ originalJobId: string }>();
  const { t } = useTranslation();
  const showOverBusinessLimitBlockingModal = useShowOverBusinessLimitModal();
  const showJobLimitReached = useShowJobLimitReached();
  const pageTitle = t('repostJobPage.title');

  useEffect(() => {
    dispatch(loadDashboardJobs());
  }, [dispatch]);

  if (showJobLimitReached) {
    return <JobLimitReached pageTitle={pageTitle} />;
  }

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={pageTitle} />

      {showOverBusinessLimitBlockingModal && <UpdateYourBusinessesModal />}

      <If condition={supportTier === SupportLevel.TIER_3}>
        <Then>
          <JobPostingNotAvailable />
        </Then>
        <Else>
          <Stack spacing="medium" tone="transparent">
            <Header title={t('repostJobPage.heading')} />
            <FormCard>
              <If condition={alreadyLoadedOriginalJob}>
                <Then>
                  <RepostFormUseExistingDraft originalJobId={originalJobId} />
                </Then>
                <Else>
                  <RepostFormFetchFromOriginalJob
                    originalJobId={originalJobId}
                  />
                </Else>
              </If>
            </FormCard>
          </Stack>
        </Else>
      </If>
    </Stack>
  );
};
