import { Application, ApplicationStatus } from '@common/types';
import { APPLICANT_STATUS } from '@seek/je-shared-data';
import { NO_STATUS } from '../constants/data';

const {
  NOT_SUITABLE,
  SHORTLISTED,
  SCHEDULED_INTERVIEW,
  OFFERED_JOB,
  ACCEPTED_OFFER
} = APPLICANT_STATUS;

export function isStatusFiltered(
  filters: ApplicationStatus[],
  status?: ApplicationStatus
) {
  if (!filters.length) {
    return status !== APPLICANT_STATUS.NOT_SUITABLE;
  }
  if (status === undefined) {
    return filters.includes(NO_STATUS);
  }
  return filters.includes(status);
}

export function getStatusTone(status) {
  switch (status) {
    case ACCEPTED_OFFER:
      return 'success';
    case NOT_SUITABLE:
      return 'danger';
    case SHORTLISTED:
    case SCHEDULED_INTERVIEW:
    case OFFERED_JOB:
      return 'warning';
    default:
      return 'neutral';
  }
}

export function getStatusCount(applications: Partial<Application>[]) {
  const statusCounts = {
    [NO_STATUS]: 0,
    [NOT_SUITABLE]: 0,
    [SHORTLISTED]: 0,
    [SCHEDULED_INTERVIEW]: 0,
    [OFFERED_JOB]: 0,
    [ACCEPTED_OFFER]: 0
  };

  Object.values([
    NOT_SUITABLE,
    SHORTLISTED,
    SCHEDULED_INTERVIEW,
    OFFERED_JOB,
    ACCEPTED_OFFER
  ]).forEach(
    (status) =>
      (statusCounts[status] = applications.filter(
        (application) => status === application.status
      ).length)
  );

  statusCounts[NO_STATUS] = applications.filter(
    (application) => !application.status || application.status === NO_STATUS
  ).length;

  return statusCounts;
}
