import { isComplete as jobRoleFormComplete } from '@caja/Form/forms/jobRoleForm';
import { StepMapping } from '../mapToStep';
import { Step } from '../types';

export default function resolveJobInfoForm({
  showJobRoleForm,
  data
}: Pick<StepMapping, 'showJobRoleForm' | 'data'>): Step {
  const jobRoleSupportedAndIncomplete =
    showJobRoleForm && !jobRoleFormComplete(data);

  if (jobRoleSupportedAndIncomplete) {
    return 'job-role-form';
  }

  return 'info-form';
}
