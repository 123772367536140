import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent } from '@components/Card';
import { Text } from '@components/Text';

type ApplicantStatusChangeInfoProps = {
  testID: string;
  applicantName?: string;
  applicantStatus?: string;
};

export const ApplicantStatusChangeInfo = (
  props: ApplicantStatusChangeInfoProps
) => {
  const { t } = useTranslation();
  const { testID, applicantStatus, applicantName } = props;

  if (!applicantStatus) {
    return null;
  }

  return (
    <Card marginBottom="medium">
      <CardContent>
        <Text weight="regular" testID={testID}>
          {t('applications.applicationCard.applicantMovedToStatus', {
            applicantName,
            applicantStatus: t(`applications.status.${applicantStatus}`)
          })}
        </Text>
      </CardContent>
    </Card>
  );
};
