import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { openExternalURL } from '@common/helpers/externalConnection';
import { Box } from '@components/Box';
import { CalloutText } from '@components/CalloutText';
import { Column } from '@components/Column';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';

type MoreBusinessesCalloutProps = {
  supportSalesEmail?: string;
  brandNameText: string;
  supportSalesWhatsApp?: string;
};

export const MoreBusinessesCallout = ({
  supportSalesEmail,
  supportSalesWhatsApp,
  brandNameText
}: MoreBusinessesCalloutProps) => {
  const { t } = useTranslation();

  const openEmail = () => {
    openExternalURL(`mailto:${supportSalesEmail}`);
  };

  const openWhatsapp = () => {
    const parsedWhatsappNumber = supportSalesWhatsApp
      ?.replace(/[\+\s]/g, '')
      .trim();
    openExternalURL(`https://wa.me/${parsedWhatsappNumber}`);
  };

  return (
    <Box tone="transparent" marginBottom="large">
      <CalloutText tone="info">
        <Column flexShrink={1} tone="transparent">
          <Text weight="regular">
            {t('businessListingPage.moreBusinesses.title')}
          </Text>
          <Text>
            <Trans
              i18nKey="businessListingPage.moreBusinesses.description"
              values={{
                brandNameText,
                supportSalesEmail,
                supportSalesWhatsApp
              }}
              components={[
                <TextLink onPress={openEmail}>1</TextLink>,
                <TextLink onPress={openWhatsapp}>2</TextLink>
              ]}
            />
          </Text>
        </Column>
      </CalloutText>
    </Box>
  );
};
