import { LOGOUT_SUCCESS } from '../../auth/constants';
import * as constants from './constants';
import { ApplicationsAction, ApplicationsState } from './types';

const initialState: ApplicationsState = {
  items: {},
  applicationsStatus: {
    items: {}
  },
  filters: [],
  filteredApplications: [],
  temporaryResumeLinks: {},
  fixedPanelHeight: 0
};

export default function reducer(
  state = initialState,
  action: Partial<ApplicationsAction> = {}
): ApplicationsState {
  const {
    jobId,
    applications = { data: [] },
    data = [],
    statusCounts,
    filters = [],
    filteredApplications = [],
    applicationsStatuses,
    notes,
    notesUpdatedAt
  } = action.result || {};
  switch (action.type) {
    case constants.LOAD_COUNT_SUCCESS:
      return {
        ...state,
        applicationsStatus: {
          items: {
            ...state.applicationsStatus.items,
            ...applicationsStatuses
          }
        }
      };

    case constants.LOAD_APPLICATIONS_SUCCESS:
      if (!jobId) {
        return state;
      }

      return {
        ...state,
        items: {
          [jobId]: {
            ...applications,
            statusCounts
          }
        }
      };

    case constants.UPDATE_APPLICANT_STATUS_SUCCESS:
      if (!jobId) {
        return state;
      }

      return {
        ...state,
        items: {
          [jobId]: {
            ...state.items[jobId],
            data,
            statusCounts
          }
        },
        filteredApplications
      };

    case constants.UPDATE_APPLICANT_NOTES:
      return {
        ...state,
        filteredApplications: state.filteredApplications.map((application) => {
          if (action.payload?.applicationId === application.id) {
            return {
              ...application,
              notes,
              notesUpdatedAt
            };
          } else {
            return application;
          }
        })
      };

    case constants.LOAD_TEMPORARY_RESUME_LINK_SUCCESS:
      if (!action.payload || !action.result?.url) {
        return state;
      }

      return {
        ...state,
        temporaryResumeLinks: {
          ...state.temporaryResumeLinks,
          [action.payload.applicationId]: {
            url: action.result.url
          }
        }
      };

    case constants.LIST_FILTER:
    case constants.CLEAR_FILTER:
      return {
        ...state,
        filters,
        filteredApplications
      };

    case constants.UPDATE_FIXED_PANEL_HEIGHT:
      return {
        ...state,
        fixedPanelHeight: action.result?.height
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
