import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@store/hooks';
import {
  trackApplicationSortDropdownClicked,
  trackApplicationSortOptionClicked
} from './sortActions';

const sortingOptions = {
  applicationDate: 'applicationDate',
  distance: 'distance',
  rightToWork: 'rightToWork'
} as const;
export type SortingOptions =
  (typeof sortingOptions)[keyof typeof sortingOptions];

export function useSort({
  onSortOptionChange,
  jobId
}: {
  onSortOptionChange?: (value: SortingOptions) => void;
  jobId: string;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedSort, setSortOption] = useState<string>(
    sortingOptions.applicationDate
  );

  const sortDropdownOptions = [
    {
      value: sortingOptions.applicationDate,
      label: t('applications.sortingDropdown.options.applicationDate')
    },
    {
      value: sortingOptions.distance,
      label: t('applications.sortingDropdown.options.distance')
    },
    {
      value: sortingOptions.rightToWork,
      label: t('applications.sortingDropdown.options.rightToWork')
    }
  ];

  return {
    sortDropdownOptions,
    selectedSort,
    onPressSortDropdown: () => {
      dispatch(trackApplicationSortDropdownClicked(jobId));
    },
    onPressSortOption: (value: SortingOptions) => {
      if (value === selectedSort) {
        return;
      }

      setSortOption(value);
      onSortOptionChange && onSortOptionChange(value);
      dispatch(trackApplicationSortOptionClicked(value, jobId));
    }
  };
}
