import React, { useContext } from 'react';
import { JobRole as InnerField } from '@src/joraEmployer/pages/job/select-job-role/components/JobRoleForm/components/JobRole';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';
import { jobRoleRules } from '../rules';

interface JobRoleProps {
  industry: string;
}
export default function JobRole({ industry }: JobRoleProps) {
  const { t } = useContext(FormContext);
  return (
    <FieldWrapper<string>
      name="jobRole"
      rules={jobRoleRules(t)}
      render={(props) => <InnerField {...props} industry={industry} />}
    />
  );
}
