import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { openExternalURL } from '@common/helpers/externalConnection';
import {
  useBrandNameText,
  useSupportSalesEmail
} from '@common/hooks/localisation';
import { useSupportSalesWhatsApp } from '@common/hooks/localisation/useSupportSalesWhatsApp';
import { Box } from '@components/Box';
import { Column } from '@components/Column';
import { CommentIcon, EmailIcon } from '@components/Icon';
import { Modal } from '@components/Modal';
import { Row } from '@components/Row';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';

type UpgradeModalProps = {
  visible: boolean;
  onRequestClose: () => void;
};

export const UpgradeAccountModal = ({
  visible,
  onRequestClose
}: UpgradeModalProps) => {
  const { t } = useTranslation();
  const supportSalesEmail = useSupportSalesEmail();
  const supportSalesWhatsApp = useSupportSalesWhatsApp();
  const brandNameText = useBrandNameText();

  const openEmail = () => {
    openExternalURL(`mailto:${supportSalesEmail}`);
  };

  const openWhatsapp = () => {
    const parsedWhatsappNumber = supportSalesWhatsApp
      ?.replace(/[+\s]/g, '')
      .trim();
    openExternalURL(`https://wa.me/${parsedWhatsappNumber}`);
  };

  return (
    <Modal
      title={t('account.upgradeAccountModal.title')}
      visible={visible}
      onRequestClose={onRequestClose}
    >
      <Box marginBottom="small">
        <Text>
          {t('account.upgradeAccountModal.description', {
            brandNameText
          })}
        </Text>
      </Box>

      <Column spacing="xxsmall">
        <Text weight="regular">
          {t('account.upgradeAccountModal.cta', { brandNameText })}
        </Text>
        <Row alignItems="center" spacing="xxsmall">
          <EmailIcon variant="outline" />
          <Text>
            <Trans
              i18nKey={'account.upgradeAccountModal.email'}
              values={{ supportSalesEmail }}
              components={[<TextLink onPress={openEmail}>0</TextLink>]}
            />
          </Text>
        </Row>

        <Row alignItems="center" spacing="xxsmall">
          <CommentIcon variant="outline" />
          <Text>
            <Trans
              i18nKey={'account.upgradeAccountModal.whatsapp'}
              values={{ supportSalesWhatsApp }}
              components={[<TextLink onPress={openWhatsapp}>0</TextLink>]}
            />
          </Text>
        </Row>
      </Column>
    </Modal>
  );
};
