import React from 'react';
import { useTranslation } from 'react-i18next';
import { UpgradeAccountModalWithButton } from '@common/components/Account/components';
import { UpgradeAccountBanner } from '@common/components/Account/components';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { PageHeadingWithUserPackage } from '@common/components/PageHeadingWithUserPackage';
import { RequestErrorMessage } from '@common/components/RequestErrorMessage';
import { useAccountUpgradeNewUIFeature } from '@common/hooks/featureToggles';
import { useAccountUpgradeable } from '@common/hooks/featureToggles/useAccountUpgradeable';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { useRedirect } from '@common/hooks/useRedirect';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useGetUserPackage } from '@common/hooks/userPackages';
import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';
import { Box } from '@components/Box';
import { CalloutText } from '@components/CalloutText';
import { Card, CardContent } from '@components/Card';
import { Link } from '@components/Link';
import { Stack } from '@components/Stack';
import REQUEST_KEY from '../../constants/requestKeys';
import { AccountDetails } from './components';
import { useLogout } from './hooks';

export const Account = () => {
  const { t } = useTranslation();
  const { redirect } = useRedirect();
  const { logout } = useLogout();
  const countryCode = useSiteCountryCode();
  const { currentUser } = useAppSelector(getUser);
  const { givenName, surname, mobile, email } = currentUser;
  const { packageType } = useGetUserPackage();
  const accountUpgradeable = useAccountUpgradeable();
  const newAccountUpgradeUI = useAccountUpgradeNewUIFeature();
  const pageTitle = t('common.action.account');

  const freeAndUpgradeableAccount =
    accountUpgradeable && packageType === 'free';

  useRedirectUnauthenticatedUser({ to: `/${countryCode}/login` });

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={pageTitle} />

      <Stack
        direction={{ desktop: 'row', tablet: 'row', mobile: 'column' }}
        spacing={{ desktop: 'none', tablet: 'none', mobile: 'small' }}
        paddingTop="medium"
        paddingBottom="small"
        paddingHorizontal="medium"
        tone="transparent"
        justifyContent="space-between"
        alignItems={{
          desktop: 'baseline',
          tablet: 'baseline',
          mobile: 'stretch'
        }}
      >
        <PageHeadingWithUserPackage heading={t('common.action.account')} />

        {!newAccountUpgradeUI && freeAndUpgradeableAccount && (
          <UpgradeAccountModalWithButton
            pageTitle={pageTitle}
            buttonText={t('accountPage.actions.upgradeAccount')}
          />
        )}
      </Stack>

      <Stack spacing="medium" tone="transparent">
        {newAccountUpgradeUI && freeAndUpgradeableAccount && (
          <UpgradeAccountBanner pageTitle={pageTitle} />
        )}

        <Card>
          <CardContent>
            <Stack spacing="xsmall">
              <AccountDetails
                givenName={givenName}
                surname={surname}
                email={email}
                mobile={mobile}
              />
            </Stack>

            {!newAccountUpgradeUI && freeAndUpgradeableAccount && (
              <Box marginTop="small">
                <CalloutText tone="info">
                  {t('accountPage.manageBusinesses')}
                </CalloutText>
              </Box>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Stack spacing="xsmall">
              <Link
                onPress={redirect(`/${countryCode}/account/update-password`)}
              >
                {t('updatePasswordForm.action.updatePassword')}
              </Link>
              <Link
                onPress={redirect(`/${countryCode}/account/delete-account`)}
              >
                {t('deleteAccountSuccessPage.title')}
              </Link>
              <Link onPress={logout}>{t('common.action.logout')}</Link>

              <RequestErrorMessage requestKey={REQUEST_KEY.AUTH.LOGOUT} />
            </Stack>
          </CardContent>
        </Card>

        <Box
          paddingLeft={{
            mobile: 'medium',
            tablet: 'xlarge',
            desktop: 'xlarge'
          }}
          paddingRight={{
            mobile: 'medium',
            tablet: 'xlarge',
            desktop: 'xlarge'
          }}
          tone="transparent"
        >
          <Box
            paddingLeft={{
              mobile: 'xsmall',
              tablet: 'small',
              desktop: 'small'
            }}
            paddingRight={{
              mobile: 'xsmall',
              tablet: 'small',
              desktop: 'small'
            }}
            tone="transparent"
          >
            <Link onPress={redirect(`/${countryCode}/`)}>
              {t('common.action.backToHome')}
            </Link>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};
