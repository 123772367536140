export enum EmptyApplicationsType {
  EXPIRED_APPLICATIONS = 'EXPIRED_APPLICATIONS',
  INACTIVE_JOB_WITH_NO_APPLICATIONS = 'INACTIVE_JOB_WITH_NO_APPLICATIONS',
  NEW_ACTIVE_JOB_WITH_NO_APPLICATIONS = 'NEW_ACTIVE_JOB_WITH_NO_APPLICATIONS',
  OLD_ACTIVE_JOB_WITH_NO_APPLICATIONS = 'OLD_ACTIVE_JOB_WITH_NO_APPLICATIONS',
  NO_FILTERED_APPLICATIONS_ON_DEFAULT_FILTER = 'NO_FILTERED_APPLICATIONS_ON_DEFAULT_FILTER',
  NO_FILTERED_APPLICATIONS_ON_FILTER = 'NO_FILTERED_APPLICATIONS_ON_FILTER',
  ACTIVE_JOB_WITH_NO_MATCHED_APPLICATIONS = 'ACTIVE_JOB_WITH_NO_MATCHED_APPLICATIONS',
  ACTIVE_JOB_WITH_NO_OTHER_APPLICATIONS_FOR_UPGRADABLE_JOB = 'ACTIVE_JOB_WITH_NO_OTHER_APPLICATIONS_FOR_UPGRADABLE_JOB',
  ACTIVE_JOB_WITH_NO_OTHER_APPLICATIONS = 'ACTIVE_JOB_WITH_NO_OTHER_APPLICATIONS',
  INACTIVE_JOB_WITH_NO_MATCHED_APPLICATIONS = 'INACTIVE_JOB_WITH_NO_MATCHED_APPLICATIONS',
  INACTIVE_JOB_WITH_NO_OTHER_APPLICATIONS = 'INACTIVE_JOB_WITH_NO_OTHER_APPLICATIONS'
}
