import { useMemo } from 'react';
import {
  getApplications,
  getFilteredApplications,
  getTotalApplicationCount,
  trackApplicationListTabClicked
} from '@store/entities/applications';
import { useAppDispatch, useAppSelector } from '@store/hooks';

export function useJobApplicationDetails(jobId: string) {
  const dispatch = useAppDispatch();
  const { applicationsAvailability, data: applications } =
    useAppSelector(getApplications(jobId)) || {};
  const filteredApplications = useAppSelector(getFilteredApplications(jobId));
  const totalApplicationCount =
    useAppSelector(getTotalApplicationCount(jobId)) || 0;
  const isApplicationDataAvailable = applicationsAvailability?.available;

  const matchedApplications = useMemo(
    () =>
      filteredApplications.filter(
        (application) => application.isMatch === true
      ),
    [filteredApplications]
  );

  const otherApplications = useMemo(
    () =>
      filteredApplications.filter(
        (application) => application.isMatch === false
      ),
    [filteredApplications]
  );

  return {
    hasApplicationsCount: !!(
      isApplicationDataAvailable &&
      totalApplicationCount &&
      totalApplicationCount > 0
    ),
    isApplicationDataAvailable,
    filteredApplicationsCount: filteredApplications.length || 0,
    filteredApplications: {
      matched: matchedApplications,
      other: otherApplications
    },
    allApplications: applications,
    handleOnTabPress: (tabIndex) =>
      dispatch(
        trackApplicationListTabClicked({
          jobId,
          tab: tabIndex === 0 ? 'matched' : 'other'
        })
      )
  };
}
