import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { JobRole } from './JobRole';
import { useJobRoleValidation } from './hooks/useJobRoleValidation';

type JobRoleControllerProps<T extends FieldValues> = UseControllerProps<T> & {
  industry: string;
};

export const JobRoleController = <T extends FieldValues>({
  name,
  control,
  industry
}: JobRoleControllerProps<T>) => {
  const jobRoleRules = useJobRoleValidation();
  return (
    <Controller
      control={control}
      name={name}
      rules={jobRoleRules}
      render={({ field: { onChange, value }, formState }) => {
        return (
          <JobRole
            onChange={onChange}
            value={value}
            error={formState?.errors[name]?.message as string}
            industry={industry}
          />
        );
      }}
    />
  );
};
