import { useCallback } from 'react';
import { isServer } from '@common/helpers/detection';
import { getUser } from '@store/auth';
import { getDevTools } from '@store/config';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { trackBrowserDetails } from '@store/platform/actions';

export function useWebShell() {
  const devToolsEnabled = useAppSelector(getDevTools());
  const { currentUser } = useAppSelector(getUser);
  const dispatch = useAppDispatch();

  const shouldSetRaygun =
    !isServer() && !devToolsEnabled && typeof Raygun !== 'undefined';

  const setRaygunUserId = useCallback(() => {
    if (shouldSetRaygun && currentUser.id) {
      Raygun.setUser(currentUser.id);
    }
  }, [shouldSetRaygun, currentUser?.id]);

  const trackBrowserInformation = useCallback(() => {
    dispatch(trackBrowserDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    devToolsEnabled,
    setRaygunUserId,
    trackBrowserInformation
  };
}
