import { StepMapping } from '../mapToStep';
import { Step } from '../types';

export default function resolveJobRoleForm({
  showJobRoleForm
}: Pick<StepMapping, 'showJobRoleForm'>): Step {
  if (!showJobRoleForm) {
    return 'info-form';
  }

  return 'job-role-form';
}
