import type { StepMap } from './types';

export const DEFAULT_STEP_MAP: StepMap = [
  {
    path: 'post-job',
    step: 'info-form',
    titleKey: 'postJobPage.heading'
  },
  {
    path: 'select-job-role',
    step: 'job-role-form',
    titleKey: 'selectJobRolePage.heading'
  }
];
