import { trackSelectRoleLinkoutClick } from '@store/entities/jobs/actions';
import { useAppDispatch } from '@store/hooks';

export function useTrackLink() {
  const dispatch = useAppDispatch();

  return {
    trackLinkoutClick: () => dispatch(trackSelectRoleLinkoutClick())
  };
}
