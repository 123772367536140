import { useLocation } from 'react-router-native';
import { useRedirect } from '@common/hooks/useRedirect';
import { saveDraftForm } from '@store/draft';
import { JOB_DRAFT_FORM } from '@store/draft/constants';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { JobRoleFormFields } from '../components/JobRoleForm/types';

const useSaveRoleInJobDraft = () => {
  const dispatch = useAppDispatch();

  const saveRoleInJobDraft = (data: JobRoleFormFields) => {
    dispatch(
      saveDraftForm(JOB_DRAFT_FORM, {
        ...data
      })
    );
  };

  return saveRoleInJobDraft;
};

const useRedirectToPostJobPage = () => {
  const { search } = useLocation();
  const { redirect } = useRedirect();
  const { countryCode } = useAppSelector(getLocalisation);

  return redirect(`/${countryCode}/post-job${search}`);
};

export const useSelectJobRole = () => {
  const saveRoleInJobDraft = useSaveRoleInJobDraft();
  const redirectToPostJobPage = useRedirectToPostJobPage();

  return {
    saveRoleInJobDraft,
    redirectToPostJobPage
  };
};
