import { EventTypes } from 'redux-segment';

export const TRACK_MAIN_EMPLOYER_BRAND_LINK_CLICKED =
  'jora-employer/entities/jobs/TRACK_MAIN_EMPLOYER_BRAND_LINK_CLICKED';

export function trackSelectJobTitleLinkoutClick() {
  return {
    type: TRACK_MAIN_EMPLOYER_BRAND_LINK_CLICKED,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Main employer brand button clicked',
          parameters: {
            type: 'select-job-title'
          }
        }
      }
    }
  };
}
