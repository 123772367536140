import { getJobAge, isJobClosedOrExpired } from '@common/helpers/jobs';
import {
  getApplications,
  getApplicationsFilters
} from '@store/entities/applications';
import { getJobById } from '@store/entities/jobs';
import { useAppSelector } from '@store/hooks';

export function useNps(jobId: string) {
  const NPS_DISPLAY_INDEX = 3;
  const NPS_MINIMUM_JOB_AGE = 3;
  const { createdAt, status } = useAppSelector(getJobById(jobId));
  const { totalApplication = 0 } = useAppSelector(getApplications(jobId)) || {};

  const npsTrackingData = {
    page: 'applicant-list',
    applicantsReceived: true,
    userType: 'employer',
    jobId
  };

  const isJobLiveAndGreaterThan3Days =
    !isJobClosedOrExpired(status) && getJobAge(createdAt) > NPS_MINIMUM_JOB_AGE;
  const minNpsRenderPosition = Math.min(
    totalApplication - 1,
    NPS_DISPLAY_INDEX
  );

  return {
    shouldRenderNPS: (applicationIndex: number) =>
      isJobLiveAndGreaterThan3Days &&
      minNpsRenderPosition &&
      minNpsRenderPosition === applicationIndex,
    npsTrackingData
  };
}

export function useApplicationList(jobId: string) {
  const filters = useAppSelector(getApplicationsFilters());
  const jobCountryCode = useAppSelector(getJobById(jobId)).countryCode || '';

  return {
    filters,
    jobCountryCode
  };
}
