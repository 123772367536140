import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@components/Stack';
import CajaWrapper from './CajaWrapper';
import { InfoForm, JobRoleForm } from './Form/forms';
import { CajaContext } from './context';
import { toCajaContext } from './context/toCajaContext';
import { hydrateDefaultProps } from './props/hydrateDefaultProps';
import { mapToStep } from './steps';
import { type CajaProps } from './types';

export default function CajaLoading(incomingProps: CajaProps) {
  const props = hydrateDefaultProps(incomingProps);
  const { t } = useTranslation();
  const cajaContext = toCajaContext({ ...props, t });
  const currentStep = mapToStep({
    stepMap: cajaContext.stepMap,
    path: props.currentPath,
    showJobRoleForm: props.showJobRoleForm,
    data: {}
  });
  return (
    <CajaWrapper
      {...props}
      step={currentStep}
      showMissingRole={currentStep === 'job-role-form'}
      showProgress={props.showProgress && currentStep !== 'job-role-form'}
    >
      <CajaContext.Provider value={cajaContext}>
        <Fragment>
          <Stack spacing="large">
            {currentStep === 'job-role-form' && <JobRoleForm />}
            {currentStep === 'info-form' && <InfoForm />}
          </Stack>
        </Fragment>
      </CajaContext.Provider>
    </CajaWrapper>
  );
}
