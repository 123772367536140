import type { StepMap } from '../../steps/types';

export const stepMap: StepMap = [
  {
    path: 'post-job',
    step: 'info-form',
    titleKey: 'postJobPage.heading'
  },
  {
    path: 'select-job-role',
    step: 'job-role-form',
    titleKey: 'selectJobRolePage.heading'
  },
  {
    path: 'create-business',
    step: 'business-form',
    titleKey: 'createBusinessPage.heading.addNewBusiness'
  },
  {
    path: 'register',
    step: 'register-form',
    titleKey: 'registerPage.title'
  },
  {
    path: 'verify-email',
    step: 'verify-form',
    titleKey: 'verifyEmailPage.heading.fromPostJob'
  },
  {
    path: 'verify-email-success',
    step: 'verify-success-form',
    titleKey: 'verifyEmailPage.heading.fromPostJob'
  }
];
