import React from 'react';
import { useTranslation } from 'react-i18next';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { PostJobForm } from '@common/componentsV2/PostJobForm';
import { useLoadJobFromParamsIntoDraft } from '@common/componentsV2/PostJobForm/hooks/useLoadJobIntoDraft/useLoadJobFromParamsIntoDraft';
import { useSubmitEditJob } from '@common/componentsV2/PostJobForm/hooks/useSubmitEditJob/useSubmitEditJob';
import REQUEST_KEY from '@common/constants/requestKeys';
import { useRedirectToJobNotFoundIfError } from '@common/hooks/useRedirectToJobNotFoundIfError';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { useRedirectToJobNotFoundIfJobNotEditable } from './hooks/useRedirectToJobNotFoundIfJobNotEditable';
import { useRedirectToLoginPageIfUnauthenticated } from './hooks/useRedirectToLoginPageIfUnauthenticated';

export const EditJob = () => {
  useRedirectToLoginPageIfUnauthenticated();
  useRedirectToJobNotFoundIfError();
  useRedirectToJobNotFoundIfJobNotEditable();

  useLoadJobFromParamsIntoDraft();

  const { submitEditJob } = useSubmitEditJob();

  const { t } = useTranslation();

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('editJobPage.title')} />
      <Stack spacing="medium" tone="transparent">
        <Box paddingTop="medium" paddingLeft="medium" tone="transparent">
          <Heading level="h1">{t('editJobPage.heading')}</Heading>
        </Box>

        <Card>
          <CardContent>
            <Stack spacing="large">
              <PostJobForm
                mode="EDIT"
                submitPostJob={submitEditJob}
                requestKey={REQUEST_KEY.JOB.EDIT}
              />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};
