import { TFunction } from 'react-i18next';
import { User } from '@common/types';
import { CompleteData } from '@src/caja';
import { create as createJobAction } from '@store/entities/jobs/actions/create';
import { useAppDispatch } from '@store/hooks';
import {
  BusinessRequestData,
  JobRequestData,
  JobRequestUserData
} from './types';

type CreateJobProps = {
  user: User;
  job: CompleteData['job'];
  business: CompleteData['business'] & { id: string; token: string };
  sourceParams: Record<string, string>;
};

const toJobRequestData = (
  job: CompleteData['job'],
  sourceParams: Record<string, string>
): JobRequestData => {
  return {
    jobDescription: job.jobDescription,
    jobType: job.jobType,
    jobTitle: job.jobTitle,
    shiftAvailability: job.shiftAvailability,
    rightToWork: job.rightToWork,
    startDate: job.startDate,
    workExperience: job.workExperience,
    industry: job.industry,
    jobRole: job.jobRole,
    salary: job.salary,
    sourceParams
  };
};

const toBusinessData = (
  business: CreateJobProps['business']
): BusinessRequestData => {
  return {
    id: business.id,
    geolocation: business.businessAddress,
    name: business.name,
    recruitmentCompanyName: business.recruitmentCompanyName,
    token: business.token
  };
};

const toUserData = (user: User): JobRequestUserData => {
  return {
    id: user.id,
    mobile: user.mobile
  };
};

export const useCreateJob = (t: TFunction) => {
  const dispatch = useAppDispatch();

  return async ({ user, job, business, sourceParams }: CreateJobProps) => {
    const userData = toUserData(user);
    const jobData = toJobRequestData(job, sourceParams);
    const businessData = toBusinessData(business);

    const response = await dispatch(
      createJobAction({ userData, businessData, t, ...jobData })
    );

    return {
      data: response?.result,
      isSuccessful: !response?.error
    };
  };
};
