import React from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Suggestion } from './AutoSuggest';
import { useAutoSuggestStyle } from './hooks';

export const NoSuggestionsList = ({
  noResultsText,
  testID
}: {
  noResultsText: string;
  testID?: string;
}) => {
  const resolvedStyles = useAutoSuggestStyle({ tone: 'success' });

  return (
    <View style={resolvedStyles.suggestionList}>
      <Box margin="small">
        <Text tone="neutralLight" testID={testID}>
          {noResultsText}
        </Text>
      </Box>
    </View>
  );
};

export const SuggestionList = ({
  suggestions,
  onSuggestionSelected,
  searchTerm,
  tone,
  testID
}: {
  suggestions: Suggestion[];
  onSuggestionSelected: (suggestion: Suggestion) => void;
  searchTerm: string | undefined;
  tone: 'neutral' | 'success' | 'danger' | 'successMuted';
  testID?: string;
}) => {
  const resolvedStyles = useAutoSuggestStyle({ tone });

  function highlightedSearchTerm(searchText = '', suggestionText: string) {
    const startIndex = suggestionText
      .toLowerCase()
      .indexOf(searchText.toLowerCase());
    const endIndex = startIndex + searchText.length;

    return { startIndex, endIndex };
  }

  function renderSuggestionText(suggestionText: string) {
    const highlightAt = highlightedSearchTerm(searchTerm, suggestionText);

    return (
      <Text>
        <Text>{suggestionText.slice(0, highlightAt.startIndex)}</Text>
        <Text weight="bold">
          {suggestionText.slice(highlightAt.startIndex, highlightAt.endIndex)}
        </Text>
        <Text>{suggestionText.slice(highlightAt.endIndex)}</Text>
      </Text>
    );
  }

  const renderSuggestionList = () => {
    return suggestions.map((item) => (
      <TouchableOpacity
        key={`${item.key}`}
        onPress={() => onSuggestionSelected(item)}
      >
        <Box padding="small" testID={`${testID}-${item.key}`}>
          {renderSuggestionText(item.displayText)}
        </Box>
      </TouchableOpacity>
    ));
  };

  return (
    <ScrollView style={resolvedStyles.suggestionList}>
      {renderSuggestionList()}
    </ScrollView>
  );
};
