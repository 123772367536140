import React, { PropsWithChildren, useEffect } from 'react';
import { type DimensionValue, View } from 'react-native';
import { GridOverlay } from '@common/components/GridOverlay';
import { When } from '@common/components/When';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Stack } from '@components/Stack';
import { UniversalLoader } from '../UniversalLoader';
import { JoraEmployerFooter } from './components/JoraEmployerFooter';
import { JoraEmployerHeader } from './components/JoraEmployerHeader';
import { NavMenu } from './components/NavMenu';
import { useRedirectUserWithLimitedAccess } from './hooks/useRedirectUserWithLimitedAccess';
import { useWebShell } from './hooks/useWebShell';

export const Shell = ({ children }: PropsWithChildren) => {
  useRedirectUserWithLimitedAccess();
  const localisation = useAppSelector(getLocalisation);

  const { devToolsEnabled, setRaygunUserId, trackBrowserInformation } =
    useWebShell();

  useEffect(() => {
    trackBrowserInformation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  setRaygunUserId();

  return (
    <>
      <UniversalLoader />
      <View style={{ minHeight: '100vh' as DimensionValue }}>
        <When condition={Boolean(devToolsEnabled)}>
          <GridOverlay />
        </When>

        <JoraEmployerHeader />
        <NavMenu />
        <Stack
          tone="transparent"
          alignItems="center"
          justifyContent="space-between"
          flex={1}
        >
          <Stack
            maxWidth="xxlarge"
            minWidth="medium"
            width="full"
            tone="transparent"
          >
            {children}
          </Stack>
          <Stack width="full">
            <JoraEmployerFooter localisation={localisation} />
          </Stack>
        </Stack>
      </View>
    </>
  );
};
