import React from 'react';
import { PostJobForm } from '@common/componentsV2/PostJobForm';
import { useLoadJobIntoDraft } from '@common/componentsV2/PostJobForm/hooks/useLoadJobIntoDraft';
import { useSubmitPostJob } from '@common/componentsV2/PostJobForm/hooks/useSubmitPostJob';
import REQUEST_KEY from '@common/constants/requestKeys';

/**
 * Renders a repost form, fetching the original job from the server
 * and loading it into the draft store.
 * @param originalJobId The id of the original job to repost
 * @returns A repost form
 */
export const RepostFormFetchFromOriginalJob = ({
  originalJobId
}: {
  originalJobId: string;
}) => {
  const { submitPostJob } = useSubmitPostJob({ originalJobId });
  useLoadJobIntoDraft({ jobId: originalJobId });

  return (
    <PostJobForm
      mode="REPOST"
      submitPostJob={submitPostJob}
      requestKey={REQUEST_KEY.JOB.CREATE}
    />
  );
};
