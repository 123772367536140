export function useRichTextTransform() {
  const mappings = [{ pattern: /<(\/?)b>/g, value: '<$1strong>' }];

  const transform = (text: string) => {
    return mappings.reduce(
      (acc, { pattern, value }) => acc.replace(pattern, value),
      text
    );
  };

  return {
    transform
  };
}
