import { useLocation, useParams } from 'react-router-native';
import { PostJobFormFields } from '@common/components/PostJobForm/types';
import { useRedirect } from '@common/hooks/useRedirect';
import { getDraftJob, saveDraftForm } from '@store/draft';
import { JOB_DRAFT_FORM } from '@store/draft/constants';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { JobRoleFormFields } from '../../select-job-role/components/JobRoleForm/types';

const useSaveRoleInJobDraft = () => {
  const dispatch = useAppDispatch();
  const draftJob = useAppSelector(getDraftJob) as PostJobFormFields &
    JobRoleFormFields;

  const saveRoleInJobDraft = (data: JobRoleFormFields) => {
    dispatch(
      saveDraftForm(JOB_DRAFT_FORM, {
        ...draftJob,
        ...data
      })
    );
  };

  return saveRoleInJobDraft;
};

const useRedirectToPostJobPage = () => {
  const { search } = useLocation();
  const { redirect } = useRedirect();
  const { countryCode } = useAppSelector(getLocalisation);

  return redirect(`/${countryCode}/post-job${search}`);
};

const useRedirectToRepostJobPage = () => {
  const { redirect } = useRedirect();
  const { countryCode } = useAppSelector(getLocalisation);
  const { originalJobId } = useParams<{ originalJobId: string }>();

  return redirect(`/${countryCode}/jobs/${originalJobId}/repost-job`);
};

export const useSelectJobRole = () => {
  const saveRoleInJobDraft = useSaveRoleInJobDraft();
  const redirectToPostJobPage = useRedirectToPostJobPage();
  const redirectToRepostJobPage = useRedirectToRepostJobPage();

  return {
    saveRoleInJobDraft,
    redirectToPostJobPage,
    redirectToRepostJobPage
  };
};
