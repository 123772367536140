import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Dropdown, Field } from '@components/FormElements';
import { useJobIndustry } from './hooks/useJobIndustry';

type JobIndustryProps = {
  value: string;
  onChange: () => void;
  error?: string;
};

export const JobIndustry = (props: JobIndustryProps) => {
  const { t } = useTranslation();
  const { jobIndustryOptions } = useJobIndustry();
  const { error, onChange, value } = props;

  return (
    <Field
      zIndex={10}
      label={t('jobRoleForm.fieldLabel.industry')}
      message={
        error && (
          <Box marginTop="xxsmall">
            <ErrorMessage>{error}</ErrorMessage>
          </Box>
        )
      }
    >
      <Box zIndex={11}>
        <Dropdown
          items={jobIndustryOptions}
          selectedItem={value}
          onChange={onChange}
          placeholder={t('job.industry.select')}
        />
      </Box>
    </Field>
  );
};
