import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { JobIndustry } from './JobIndustry';
import { useJobIndustryValidation } from './hooks/useJobIndustryValidation';

export const JobIndustryController = <T extends FieldValues>({
  name,
  control
}: UseControllerProps<T>) => {
  const jobIndustryRules = useJobIndustryValidation();

  return (
    <Controller
      control={control}
      name={name}
      rules={jobIndustryRules}
      render={({ field: { onChange, value }, formState }) => {
        return (
          <JobIndustry
            onChange={onChange}
            value={value}
            error={formState?.errors[name]?.message as string}
          />
        );
      }}
    />
  );
};
