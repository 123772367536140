import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { CajaContext } from '@src/caja/context';
import { Stack } from '@components/Stack';
import { useListenForChanges } from '../hooks';
import { FormContext } from './FormContext';
import { hydrateProps } from './props';
import { JobIndustry, JobRole, Submit } from './topLevelFields';
import type { FormProps } from './types';
import { CompleteJobRoleFormData, IncompleteJobRoleFormData } from './types';

export default function JobRoleForm(opts: FormProps) {
  const { defaultValues, onChange, onSubmit } = hydrateProps(opts);
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid, isSubmitted }
  } = useForm<IncompleteJobRoleFormData>({
    defaultValues,
    mode: 'onBlur'
  });

  const { t } = useContext(CajaContext);

  const [industry, setIndustry] = useState<string | undefined>(
    defaultValues?.jobIndustry
  );

  const { updateDebounce } = useContext(CajaContext);

  function verifySubmit(data: IncompleteJobRoleFormData) {
    onSubmit(data as CompleteJobRoleFormData);
  }

  function interceptChange(data: IncompleteJobRoleFormData) {
    setIndustry(data.jobIndustry || '');
    onChange(data);
  }

  useListenForChanges({ watch, updateDebounce, onChange: interceptChange });

  return (
    <FormContext.Provider value={{ control, errors, t }}>
      <Stack spacing="large">
        <JobIndustry />
        {industry && <JobRole industry={industry} />}
        <Submit onPress={handleSubmit(verifySubmit)}>
          {t('common.action.continue')}
        </Submit>

        {isSubmitted && !isValid ? (
          <ErrorMessage>{t('validations.general.summary')}</ErrorMessage>
        ) : null}
      </Stack>
    </FormContext.Provider>
  );
}
