import type { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import type { Step } from './types/Step';

/**
 * Converts a step and country code into a progress path for the Progress component.
 * @param countryCode The country code.
 * @param step The step.
 * @returns The progress path.
 */
export function stepToProgressPath(
  countryCode: SupportedCountryCode,
  step: Step
) {
  if (step === 'job-role-form') return `/${countryCode}/select-job-role`;
  if (step === 'info-form') return `/${countryCode}/post-job`;
  if (step === 'business-form') return `/${countryCode}/create-business`;
  if (step === 'register-form') return `/${countryCode}/register`;
  if (step === 'verify-form') return `/${countryCode}/register`;
  if (step === 'verify-success-form') return `/${countryCode}/register`;

  return `/${countryCode}/post-job`;
}
