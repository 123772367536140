import { rulesAreValid } from '@lib/form';
import { jobIndustryRules, jobRoleRules } from './rules';
import type { IncompleteJobRoleFormData } from './types';

/**
 * Checks if the provided job role form data is complete.
 * @param data - The job role form data to check.
 * @returns A boolean indicating whether the data is complete or not.
 */
export function isComplete(data: IncompleteJobRoleFormData): boolean {
  const t = () => 'A';
  return [
    rulesAreValid(data, 'jobIndustry', jobIndustryRules(t)),
    rulesAreValid(data, 'jobRole', jobRoleRules(t))
  ].every(Boolean);
}
