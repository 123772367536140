import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { useUpdateSourceQuery } from '@common/hooks/useUpdateSourceQuery';
import {
  useShowJobLimitReached,
  useShowOverBusinessLimitModal
} from '@common/hooks/userPackages';
import { loadDashboardJobs } from '@store/entities/jobs';
import { useAppDispatch } from '@store/hooks';
import { Box } from '@components/Box';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { JobLimitReached } from '../job-limit-reached';
import { UpdateYourBusinessesModal } from '../post-job/components';
import { JobRoleForm } from './components/JobRoleForm';
import { RoleNotAvailable } from './components/RoleNotAvailable';
import { useSelectJobRole } from './hooks/useSelectJobRole';

export const SelectJobRole = () => {
  useUpdateSourceQuery();
  const { t } = useTranslation();
  const { saveRoleInJobDraft, redirectToPostJobPage } = useSelectJobRole();
  const dispatch = useAppDispatch();
  const showOverBusinessLimitBlockingModal = useShowOverBusinessLimitModal();
  const showJobLimitReached = useShowJobLimitReached();
  const pageTitle = t('selectJobRolePage.heading');

  useEffect(() => {
    dispatch(loadDashboardJobs());
  }, [dispatch]);

  if (showJobLimitReached) {
    return <JobLimitReached pageTitle={pageTitle} />;
  }

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={pageTitle} />

      {showOverBusinessLimitBlockingModal && <UpdateYourBusinessesModal />}

      <Stack spacing="medium" tone="transparent">
        <Box paddingTop="medium" paddingLeft="medium" tone="transparent">
          <Heading level="h1">{t('selectJobRolePage.heading')}</Heading>
        </Box>
        <Box zIndex={10}>
          <JobRoleForm
            saveRoleInJobDraft={saveRoleInJobDraft}
            submitJobRole={redirectToPostJobPage}
          />
        </Box>
        <RoleNotAvailable />
      </Stack>
    </Stack>
  );
};
