import { useEffect, useState } from 'react';
import REQUEST_KEY from '@common/constants/requestKeys';
import { getStatusCount } from '@common/helpers/applications';
import { ClientError } from '@common/helpers/errors';
import { useAppQuery } from '@common/hooks/useAppQuery';
import { ApplicationInformation } from '@common/hooks/useAppQuery/queries/useGetApplications';
import { ApplicationStatus } from '@common/types';
import { updateError } from '@store/app';
import { getRequestState } from '@store/app';
import { getApplicationsFilters } from '@store/entities/applications';
import {
  filterList,
  loadApplicationSuccess
} from '@store/entities/applications/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

export function useFetchJobApplications(jobId: string) {
  const [selectedSort, setSortOption] = useState<string>();
  const [selectedFilters, setFilterOption] = useState<ApplicationStatus[]>([]);
  const currentFilters = useAppSelector(getApplicationsFilters());

  const result = useAppQuery(
    'getApplicationProfiles',
    { jobId, sortBy: selectedSort },
    REQUEST_KEY.APPLICATION.LOAD
  );

  useFetchJobApplicationsError(result);
  useFetchJobApplicationsSuccess(jobId, result.data, selectedFilters);

  return {
    onSortOptionChange: (value: string) => {
      setSortOption(value);
      setFilterOption(currentFilters);
      result.refetch();
    }
  };
}

function useFetchJobApplicationsError(result: any) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (result.isError) {
      const error = new ClientError('errors.defaultWithGuid', {
        guid: result.error.data?.httpError.guid,
        responseBody: result.error.data
      });

      updateError(dispatch, REQUEST_KEY.APPLICATION.LOAD, error);
    }
  }, [dispatch, result.error, result.isError]);
}

function useFetchJobApplicationsSuccess(
  jobId: string,
  response: ApplicationInformation | undefined,
  filters: ApplicationStatus[]
) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (response?.data) {
      const applications = response.data;
      const statusCounts = getStatusCount(applications);

      dispatch(loadApplicationSuccess(jobId, response, statusCounts));
      dispatch(filterList({ jobId, filters }));
    }
  }, [dispatch, jobId, response, filters]);
}

export function useFetchJobApplicationsStatus() {
  const loadApplicationError = useAppSelector(
    getRequestState(REQUEST_KEY.APPLICATION.LOAD)
  ).error;

  return {
    hasFetchApplicationsError: !!loadApplicationError
  };
}
