import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APPLICATION_STATUS_FILTER_OPTIONS } from '@common/constants/data';
import { ApplicationStatus } from '@common/types';
import {
  clearFilters as clearFiltersAction,
  filterList,
  trackFilterList
} from '@store/entities/applications/actions';
import {
  getApplications,
  getApplicationsFilters
} from '@store/entities/applications/selectors';
import { useAppDispatch, useAppSelector } from '@store/hooks';

export function useFilters(jobId: string) {
  const dispatch = useAppDispatch();
  const [newFilters, setNewFilters] = useState<ApplicationStatus[]>([]);
  const { statusCounts } = useAppSelector(getApplications(jobId));
  const selectedFilters = useAppSelector(getApplicationsFilters());

  const { t } = useTranslation();

  const availableFilters = useMemo(
    () =>
      APPLICATION_STATUS_FILTER_OPTIONS(t).map((option) => {
        const count = statusCounts?.[option.key] || 0;

        return {
          ...option,
          label: `${option.label} (${count})`
        };
      }),
    [statusCounts, t]
  );

  const applyNewFilters = useCallback(() => {
    dispatch(filterList({ jobId, filters: newFilters }));
    dispatch(trackFilterList({ jobId, filters: newFilters }));
  }, [dispatch, jobId, newFilters]);

  const clearFilters = useCallback(
    () => dispatch(clearFiltersAction({ jobId })),
    [dispatch, jobId]
  );

  return {
    availableFilters,
    selectedFilters,
    shouldShowFilterStatus: selectedFilters.length > 0,
    applyNewFilters,
    clearFilters,
    setNewFilters,
    newFilters
  };
}
