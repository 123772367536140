import { useState } from 'react';
import { Platform } from 'react-native';
import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { ExactSizes } from '@theme/tokens/size';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export type UseRichTextInputStyleProps = {
  isFocused: boolean;
  maxHeight?: Exclude<ExactSizes, 'none'>;
  minHeight?: Exclude<ExactSizes, 'none'>;
  tone?: 'neutral' | 'danger';
};

export function useRichTextInputStyle({
  isFocused,
  maxHeight = 'small',
  minHeight = 'small',
  tone = 'neutral'
}: UseRichTextInputStyleProps) {
  const {
    formElement,
    icon,
    richTextInput: {
      border,
      container: themeContainerStyle,
      innerContainer,
      editor,
      toolbar: toolbarStyle,
      toolbarIcon
    },
    spacing,
    sizes,
    typography
  } = useTheme();

  const { resolveToken } = useResolveToken();

  const resolvedMaxHeight = resolveToken(sizes, maxHeight) || sizes.small;
  const resolvedMinHeight = resolveToken(sizes, minHeight) || sizes.small;
  const [currentHeight, setCurrentHeight] = useState(resolvedMinHeight);

  const { borderColor: defaultBorderColor } =
    resolveToken(formElement.color, tone) || {};

  const containerStyle = {
    ...themeContainerStyle,
    borderColor: isFocused
      ? resolveToken(border.color, 'focused')
      : defaultBorderColor
  };

  const innerContainerStyle = {
    ...innerContainer,
    opacity: Platform.OS === 'android' ? 0.99 : undefined // Enable hardware acceleration implicitly to avoid app crashing on Android due to WebView
  };

  const contentFontFamily = `${quoteFontName(
    typography.fontFamily
  )}, ${quoteFontName(typography.fontFamilyWebFallback)}`;

  const {
    size: textSize,
    lineHeight: textLineHeight,
    letterSpacing: textLetterSpacing
  } = resolveToken(typography.text, 'standard') || {};

  const fontSize = resolveToken(typography.fontSizes, textSize);
  const fontWeight = resolveToken(typography.fontWeights, 'light');
  const lineHeight = resolveToken(typography.lineHeights, textLineHeight) || 0;
  const letterSpacing = resolveToken(
    typography.letterSpacing,
    textLetterSpacing
  );

  const editorStyle = {
    ...editor,
    contentCSSText: `
        font-family: ${contentFontFamily};
        font-weight: ${fontWeight};
        font-size: ${fontSize};
        letter-spacing: ${letterSpacing};
        line-height: ${lineHeight}px;
      `
  };

  const editorSupportStyle = {
    maxHeight: Math.max(resolvedMaxHeight, resolvedMinHeight),
    minHeight: resolvedMinHeight,
    currentHeight,
    lineHeight,
    marginBottom: spacing.xxsmall
  };

  const adjustEditorHeight = (contentHeight: number) => {
    if (
      contentHeight > editorSupportStyle.minHeight &&
      currentHeight < editorSupportStyle.maxHeight
    ) {
      setCurrentHeight(Math.min(contentHeight, editorSupportStyle.maxHeight));
    } else if (
      contentHeight < editorSupportStyle.maxHeight &&
      currentHeight > editorSupportStyle.minHeight
    ) {
      setCurrentHeight(Math.max(contentHeight, editorSupportStyle.minHeight));
    }
  };

  const iconSize = Platform.OS === 'web' ? 'xlarge' : 'large';
  const toolbarIconStyle = {
    ...toolbarIcon,
    iconSize: resolveToken(icon.sizes, iconSize)
  };

  return {
    adjustEditorHeight,
    containerStyle,
    innerContainerStyle,
    editorStyle,
    editorSupportStyle,
    toolbarStyle,
    toolbarIconStyle
  };
}

function quoteFontName(fontName: string) {
  return fontName.includes(' ') ? `"${fontName}"` : fontName;
}
