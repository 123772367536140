import { useTranslation } from 'react-i18next';
import { JOB_INDUSTRY_AND_ROLE } from '@seek/je-shared-data';

export const useJobIndustry = () => {
  const { t } = useTranslation();

  const jobIndustryOptions = JOB_INDUSTRY_AND_ROLE.map((option) => {
    return {
      key: option.industry,
      value: option.industry,
      label: t(`job.industry.${option.industry}`)
    };
  });

  return { jobIndustryOptions };
};
