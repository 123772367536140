import { createBrowserHistory } from 'history';
import { errorTrackingMiddleware } from '../../../common/helpers/raygun';
import {
  createTracker,
  injectImpersonatorDetails
} from '../../helpers/analytics';
import { createGoogleGTagMiddleware } from './createGoogleGTagMiddleware';

const createMiddlewares = (state) => {
  const middlewares = [injectImpersonatorDetails(), createTracker()];

  const gtagMiddleWare = createGoogleGTagMiddleware(state);
  if (gtagMiddleWare) {
    middlewares.push(gtagMiddleWare);
  }

  middlewares.push(errorTrackingMiddleware);
  return middlewares;
};

export function getStoreConfig() {
  const initialState = (window as any).__INITIAL_STATE__;

  return {
    initialState,
    initialHistory: createBrowserHistory(),
    fetch,
    middleware: createMiddlewares(initialState)
  };
}
