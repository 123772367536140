import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Card, CardContent } from '@components/Card';
import { Stack } from '@components/Stack';
import { JobIndustryController } from './components/JobIndustry';
import { JobRoleController } from './components/JobRole';
import { useJobRoleForm } from './hooks/useJobRoleForm';
import { JobRoleFormFields, JobRoleFormProps } from './types';

export const JobRoleForm = ({
  submitJobRole,
  saveRoleInJobDraft
}: JobRoleFormProps) => {
  const { t } = useTranslation();

  const { draftJobRole } = useJobRoleForm();
  const defaultValues = {
    industry: '',
    jobRole: ''
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitted },
    getValues
  } = useForm<JobRoleFormFields>({
    mode: 'onBlur',
    values: { ...defaultValues, ...draftJobRole }
  });

  const formUpdates = useWatch({ control });

  useEffect(() => {
    // update draft store on user interaction
    let formValues = { ...formUpdates } as JobRoleFormFields;

    if (isDirty && formUpdates !== draftJobRole) {
      if (draftJobRole.industry !== getValues('industry')) {
        formValues = { ...formValues, jobRole: '' };
      }
      saveRoleInJobDraft(formValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, formUpdates]);

  const selectedIndustry = getValues('industry');
  return (
    <Card testID="select-role-form">
      <CardContent>
        <Stack spacing="large">
          <Box zIndex={10}>
            <JobIndustryController control={control} name="industry" />
          </Box>
          {selectedIndustry ? (
            <JobRoleController
              control={control}
              name="jobRole"
              industry={selectedIndustry}
            />
          ) : null}
          <Button onPress={handleSubmit(submitJobRole)}>
            {t('common.action.continue')}
          </Button>
          {isSubmitted && !isValid ? (
            <ErrorMessage>{t('validations.general.summary')}</ErrorMessage>
          ) : null}
        </Stack>
      </CardContent>
    </Card>
  );
};
