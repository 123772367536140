import { useAccountUpgradeNewUIFeature } from '@common/hooks/featureToggles';
import { useAccountUpgradeable } from '@common/hooks/featureToggles/useAccountUpgradeable';
import { useAtJobLimit } from '../useAtJobLimit';

export const useShowJobLimitReached = (): boolean => {
  const atJobLimit = useAtJobLimit();
  const accountUpgradeable = useAccountUpgradeable();
  const accountUpgradeNewUI = useAccountUpgradeNewUIFeature();

  if (!accountUpgradeNewUI) return false;

  return atJobLimit && accountUpgradeable;
};
