import { EventTypes } from 'redux-segment';
import { SortingOptions } from './useSort';

const APPLICATION_SORT_DROPDOWN_CLICKED =
  'jora-employer/dropdown/APPLICATION_SORT_DROPDOWN_CLICKED';
const APPLICATION_SORT_OPTION_CLICKED =
  'jora-employer/dropdown/APPLICATION_SORT_OPTION_CLICKED';

export function trackApplicationSortDropdownClicked(jobId: string) {
  return {
    type: APPLICATION_SORT_DROPDOWN_CLICKED,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Application sort dropdown clicked',
          properties: { employerJobId: jobId }
        }
      }
    }
  };
}

export function trackApplicationSortOptionClicked(
  optionSelected: SortingOptions,
  jobId: string
) {
  return {
    type: APPLICATION_SORT_OPTION_CLICKED,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Application sort option clicked',
          properties: { optionSelected, employerJobId: jobId }
        }
      }
    }
  };
}
