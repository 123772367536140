import { getDraftJob } from '@store/draft';
import { useAppSelector } from '@store/hooks';
import { JobRoleFormFields } from '../types';

type UseJobRoleFormProps = {
  draftJobRole: JobRoleFormFields;
};

export const useJobRoleForm = (): UseJobRoleFormProps => {
  const { industry, jobRole } = useAppSelector(
    getDraftJob
  ) as JobRoleFormFields;

  return {
    draftJobRole: {
      industry,
      jobRole
    }
  };
};
