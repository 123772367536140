import React, { Fragment, useEffect, useState } from 'react';
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { RequestErrorMessage } from '@common/components/RequestErrorMessage';
import { getRequestState } from '@store/app';
import { getUser } from '@store/auth';
import { BusinessWithUsers } from '@store/entities/businesses/types';
import { useAppSelector } from '@store/hooks';
import { Stack } from '@components/Stack';
import { ApplicationsRecipient } from './components/ApplicationsRecipient';
import { BusinessInformation } from './components/BusinessInformation';
import { ExpectedSalaryController } from './components/ExpectedSalary';
import { JobDescriptionController } from './components/JobDescription';
import { JobStartDateController } from './components/JobStartDate';
import { JobTitleController } from './components/JobTitle';
import { JobTypeController } from './components/JobType';
import { PostJobFormActions } from './components/PostJobFormActions';
import { useFormMode } from './components/PostJobFormActions/useFormMode';
import { RightToWorkController } from './components/RightToWork';
import { ShiftAvailabilityController } from './components/ShiftAvailability/ShiftAvailabilityController';
import { WorkExperienceController } from './components/WorkExperience';
import { useSaveJobDraft } from './hooks/useSaveJobDraft';
import { PostJobFormFields, PostJobFormProps } from './types';
import { usePostJobForm } from './usePostJobForm';

// hack that we implemented as "watch"/"useWatch" was triggering re-rendering of the whole form
// we've pushed the "watch" down to this component so that re-renders only happen here
const DraftSaver = () => {
  const { saveJobDraft } = useSaveJobDraft();
  const {
    watch,
    formState: { isSubmitSuccessful }
  } = useFormContext();
  const [debounce, setDebounce] = useState<NodeJS.Timeout>();

  useEffect(() => {
    // sets up debouncing for "saveJobDraft" - required because "saveJobDraft" itself still causes the whole form to re-render
    const subscription = watch((formValues) => {
      clearTimeout(debounce);

      const timeout = setTimeout(() => {
        // ensure that any leftover debounce timers don't trigger a draft save after the form has already been submitted
        if (!isSubmitSuccessful) {
          saveJobDraft(formValues);
        }

        setDebounce(undefined);
      }, 200);

      setDebounce(timeout);
    });

    return () => {
      clearTimeout(debounce);
      subscription.unsubscribe();
    };
  });

  return <></>;
};

export const PostJobForm = ({
  submitPostJob,
  requestKey,
  mode
}: PostJobFormProps) => {
  const { t } = useTranslation();
  const { currentUser } = useAppSelector(getUser);
  const { error } = useAppSelector(getRequestState(requestKey));
  const { draftJob, business, mapBusinessToFormValues, defaultValues } =
    usePostJobForm();

  const formMethods = useForm<PostJobFormFields>({
    mode: 'onBlur',
    defaultValues,
    values: {
      ...defaultValues,
      ...draftJob,
      business: draftJob.business || business
    }
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitted },
    setValue
  } = formMethods;

  const {
    showCancelButton,
    submitButtonLabel,
    shouldRedirectBusinessCreatedTo,
    isBusinessAndEmailEditable
  } = useFormMode(mode);

  const jobType = useWatch({
    control,
    name: 'jobType',
    defaultValue: draftJob.jobType
  });

  const onSubmit = (data: PostJobFormFields) => {
    if (isValid) {
      submitPostJob(data);
    }
  };

  const handleBusinessSelected = (selectedBusiness: BusinessWithUsers) => {
    setValue(
      'business',
      { ...mapBusinessToFormValues(selectedBusiness) },
      { shouldDirty: true }
    );
  };

  return (
    <Fragment>
      <FormProvider {...formMethods}>
        <DraftSaver />
      </FormProvider>

      <Stack spacing="large">
        {currentUser.email ? (
          <ApplicationsRecipient
            email={currentUser.email}
            isEditable={isBusinessAndEmailEditable}
          />
        ) : null}

        <JobTitleController control={control} name="jobTitle" />

        <JobTypeController control={control} name="jobType" />
        {jobType ? (
          <ShiftAvailabilityController
            control={control}
            name="shiftAvailability"
          />
        ) : null}

        <RightToWorkController control={control} name="rightToWork" />
        <WorkExperienceController control={control} name="workExperience" />
        <JobStartDateController control={control} name="startDate" />

        <ExpectedSalaryController control={control} name="salary" />
        <JobDescriptionController control={control} name="jobDescription" />

        {currentUser.email ? (
          <BusinessInformation
            business={business}
            isBusinessEditable={isBusinessAndEmailEditable}
            handleBusinessSelected={handleBusinessSelected}
            shouldRedirectBusinessCreatedTo={shouldRedirectBusinessCreatedTo}
          />
        ) : null}

        <PostJobFormActions
          submit={handleSubmit(onSubmit)}
          requestKey={requestKey}
          showCancelButton={showCancelButton}
          submitButtonLabel={submitButtonLabel}
        />

        {isSubmitted && !isValid ? (
          <ErrorMessage>{t('validations.general.summary')}</ErrorMessage>
        ) : null}

        {error ? <RequestErrorMessage requestKey={requestKey} /> : null}
      </Stack>
    </Fragment>
  );
};
