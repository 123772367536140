import React from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from '@common/components/FormElements';
import { Button } from '@components/Button';
import { Column } from '@components/Column';
import { Modal } from '@components/Modal';
import { useFilters } from '../../hooks/useFilters';

type ApplicationsFilterModalProps = {
  visible: boolean;
  jobId: string;
  onRequestClose: () => void;
};

export const ApplicationsFilterModal = ({
  jobId,
  visible,
  onRequestClose
}: ApplicationsFilterModalProps) => {
  const { t } = useTranslation();
  const { availableFilters, applyNewFilters, setNewFilters, newFilters } =
    useFilters(jobId);

  const applyFilters = () => {
    applyNewFilters();
    onRequestClose();
  };

  return (
    <Modal
      title={t('applications.applicationsFilterModal.title')}
      height="fit-content"
      visible={visible}
      testID="applications-filter-modal"
      onRequestClose={onRequestClose}
    >
      <Column spacing="large">
        <MultiSelect
          value={newFilters}
          onChange={setNewFilters}
          options={availableFilters}
          testID="applications-filter-modal-filter-options"
        />
        <Button onPress={applyFilters}>
          {t('applications.applicationsFilterModal.action.applyFilter')}
        </Button>
      </Column>
    </Modal>
  );
};
