import React, { useState } from 'react';
import { View, ViewStyle } from 'react-native';
import DropDownPicker, {
  ItemType,
  ValueType
} from 'react-native-dropdown-picker';
import { DropdownDownIcon } from '@components/Icon/icons/DropdownDownIcon';
import { DropdownUpIcon } from '@components/Icon/icons/DropdownUpIcon';
import { useDropdownA11yProps } from './useDropdownA11yProps';
import {
  DropdownStyleProps,
  DropdownTone,
  useDropdownStyle,
  useTextStyle
} from './useDropdownStyle';

export type Item<T> = ItemType<T>;

export type DropdownProps<T> = {
  items: Item<T>[];
  disabled?: boolean;
  testID?: string;
  selectedItem?: Item<T>['value'];
  placeholder?: string;
  tone?: 'neutral' | 'danger';
  onChange?: (value: ValueType) => void;
  onDropdownOpen?: () => void;
} & Omit<DropdownStyleProps, 'tone'>;

const getDropdownTone = ({
  defaultTone,
  disabled
}: {
  defaultTone: DropdownTone;
  disabled?: boolean;
}) => (disabled ? 'successMuted' : defaultTone);

export const Dropdown = <T extends string>(props: DropdownProps<T>) => {
  const {
    items,
    tone,
    width,
    disabled,
    placeholder,
    testID,
    onChange,
    selectedItem = '',
    onDropdownOpen
  } = props;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(selectedItem);

  const a11yProps = useDropdownA11yProps({ disabled, value });

  const defaultTone = getDropdownTone({
    defaultTone: tone || 'neutral',
    disabled
  });

  const activeTone = getDropdownTone({
    defaultTone: 'success',
    disabled
  });

  const [dropdownTone, setTone] = useState<DropdownTone>(defaultTone);

  const {
    container: resolvedContainerStyle,
    dropdownContainerField: resolvedDropdownContainerFieldStyle
  } = useDropdownStyle({ tone: dropdownTone, width });
  const resolvedTextStyle = useTextStyle({ tone: dropdownTone });

  const renderArrowDownIcon = () => (
    <DropdownDownIcon variant="fill" tone={dropdownTone} />
  );
  const renderArrowUpIcon = () => (
    <DropdownUpIcon variant="fill" tone={dropdownTone} />
  );

  return (
    <View
      style={resolvedContainerStyle as ViewStyle}
      {...a11yProps}
      testID={`${testID ? `${testID}-` : ''}dropdownWrapper`}
    >
      <DropDownPicker
        listMode="SCROLLVIEW"
        placeholder={placeholder}
        style={resolvedDropdownContainerFieldStyle}
        dropDownContainerStyle={resolvedDropdownContainerFieldStyle}
        textStyle={resolvedTextStyle}
        testID={testID}
        open={open}
        value={selectedItem}
        items={items}
        setOpen={setOpen}
        setValue={(callback: (prevState: string) => string) => {
          const newValue = callback(selectedItem);
          onChange?.(newValue);
          setValue(newValue);
        }}
        showTickIcon={false}
        ArrowUpIconComponent={renderArrowUpIcon}
        ArrowDownIconComponent={renderArrowDownIcon}
        disabled={disabled}
        onPress={() => {
          setOpen(true);
          setTone(activeTone);
          onDropdownOpen?.();
        }}
        onClose={() => {
          setTone(defaultTone);
          setOpen(false);
        }}
        maxHeight={230}
      />
    </View>
  );
};

export default Dropdown;
