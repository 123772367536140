import { rulesAreValid } from '@lib/form';
import {
  expectedSalaryRules,
  jobDescriptionRules,
  jobStartDateRules,
  jobTitleRules,
  jobTypeRules
} from './rules';
import type { IncompleteInfoFormData } from './types';

/**
 * Checks if the provided data is complete based on the specified rules.
 * @param {Incomplete} data - The data to validate.
 * @returns A boolean value indicating whether the data is complete or not.
 */
export function isComplete(
  data: IncompleteInfoFormData,
  options: any
): boolean {
  const t = () => 'A';
  return [
    rulesAreValid(data, 'expectedSalary', expectedSalaryRules(t)),
    rulesAreValid(
      data,
      'jobDescription',
      jobDescriptionRules(t, options.jobDescriptionLength, () => {})
    ),
    rulesAreValid(data, 'jobStartDate', jobStartDateRules(t)),
    rulesAreValid(data, 'jobTitle', jobTitleRules(t)),
    rulesAreValid(data, 'jobType', jobTypeRules(t))
  ].every(Boolean);
}
