import { updateApplicantNotes } from '@store/entities/applications';
import { useAppDispatch } from '@store/hooks';

export const useUpdateApplicantNotesInStore = () => {
  const dispatch = useAppDispatch();

  return ({ notes, notesUpdatedAt, applicationId }) => {
    dispatch(
      updateApplicantNotes({
        notes,
        applicationId,
        notesUpdatedAt
      })
    );
  };
};
