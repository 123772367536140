import { BrandAndCountryConfig, Config, CountryCodeFlag } from '@common/types';

export const initialState: Config = {
  environment: '',
  siteID: undefined,
  assetsPath: '',
  featureToggles: {
    accountUpgradeNewUI: false,
    accountUpgradeable: {} as BrandAndCountryConfig,
    coins: {} as BrandAndCountryConfig,
    monetisation: {} as BrandAndCountryConfig,
    monetisationGst: {} as BrandAndCountryConfig,
    disableRegistration: {} as CountryCodeFlag,
    limitBusinessCountryCodeForBrand: {
      jora: false,
      jobsdb: false,
      jobstreetVn: false,
      jobstreetExpress: false
    },
    enableGA4Direct: false,
    disableMarket: {
      jora: {} as CountryCodeFlag,
      jobsdb: {} as CountryCodeFlag,
      jobstreetVn: {} as CountryCodeFlag,
      jobstreetExpress: {} as CountryCodeFlag
    },
    regionSelector: {
      jora: {} as CountryCodeFlag,
      jobsdb: {} as CountryCodeFlag,
      jobstreetVn: {} as CountryCodeFlag,
      jobstreetExpress: {} as CountryCodeFlag
    },
    limitJobRoles: {
      jora: {} as CountryCodeFlag,
      jobsdb: {} as CountryCodeFlag,
      jobstreetVn: {} as CountryCodeFlag,
      jobstreetExpress: {} as CountryCodeFlag
    }
  },
  analytics: {
    segmentWriteKey: {
      web: '',
      native: {
        android: '',
        iOS: ''
      }
    }
  },
  braze: {
    apiKey: '',
    baseUrl: ''
  },
  bypassRecaptcha: false,
  devTools: false,
  stripePublicKeys: {
    jora: {
      au: '',
      gb: '',
      my: '',
      nz: '',
      ph: '',
      id: ''
    },
    jobsdb: {
      sg: ''
    },
    jobstreetVn: {
      vn: ''
    },
    jobstreetExpress: {
      my: '',
      ph: '',
      sg: '',
      id: ''
    }
  },
  googleRecaptcha: {
    endpoint: '',
    sitekey: '',
    baseUrl: ''
  },
  domain: {
    jora: '',
    jobsdb: '',
    jobstreetVn: '',
    jobstreetExpress: ''
  },
  endpoints: {
    applicationService: '',
    businessService: '',
    jobService: '',
    jora: {
      localhost: '',
      bff: '',
      authenticationService: '',
      applicationService: '',
      jobService: '',
      businessService: '',
      paymentService: ''
    },
    jobsdb: {
      localhost: '',
      bff: '',
      authenticationService: '',
      applicationService: '',
      jobService: '',
      businessService: '',
      paymentService: ''
    },
    jobstreetVn: {
      localhost: '',
      bff: '',
      authenticationService: '',
      applicationService: '',
      jobService: '',
      businessService: '',
      paymentService: ''
    },
    jobstreetExpress: {
      localhost: '',
      bff: '',
      authenticationService: '',
      applicationService: '',
      jobService: '',
      businessService: '',
      paymentService: ''
    }
  },
  geocoderApi: {
    endpoint: ''
  },
  googleMaps: {
    locationSearchEndpoint: '',
    apiKey: {
      web: '',
      android: '',
      ios: ''
    }
  },
  maxBusiness: 0,
  isLocalDev: false,
  allowSecureCookies: false,
  errorTracking: {
    debugMode: false,
    raygunApiKey: {
      web: '',
      native: ''
    }
  },
  datadog: {
    clientToken: '',
    applicationId: ''
  }
};
