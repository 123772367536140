import React from 'react';
import { PostJobForm } from '@common/componentsV2/PostJobForm';
import { useSubmitPostJob } from '@common/componentsV2/PostJobForm/hooks/useSubmitPostJob';
import REQUEST_KEY from '@common/constants/requestKeys';

/**
 * Renders a repost form with the expectation that the draft
 * has alread been fully resolved and is available in the store.
 * As such, it will not attempt to load the repost job from the server.
 * @param originalJobId The id of the original job to repost
 * @returns A repost form
 */
export const RepostFormUseExistingDraft = ({
  originalJobId
}: {
  originalJobId: string;
}) => {
  const { submitPostJob } = useSubmitPostJob({ originalJobId });

  return (
    <PostJobForm
      mode="REPOST"
      submitPostJob={submitPostJob}
      requestKey={REQUEST_KEY.JOB.CREATE}
    />
  );
};
