import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterIcon } from '@components/Icon';
import { Link } from '@components/Link';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import {
  ApplicationsFilterModal,
  FilteredStatusPill,
  SortDropdown,
  useApplicationsFilterModal
} from './components';
import { SortingOptions, useFilters, useSort } from './hooks';

type Props = {
  jobId: string;
  onSortOptionChange: (value: SortingOptions) => void;
  shouldShowActions?: boolean;
};

export const ApplicationsFilterSortBar = ({
  jobId,
  shouldShowActions,
  onSortOptionChange
}: Props) => {
  const { clearFilters, selectedFilters, shouldShowFilterStatus } =
    useFilters(jobId);
  const { openFilterModal, closeFilterModal, isFilterModalOpen } =
    useApplicationsFilterModal();

  const {
    selectedSort,
    sortDropdownOptions,
    onPressSortDropdown,
    onPressSortOption
  } = useSort({ onSortOptionChange, jobId });

  return (
    <Stack
      direction={{ desktop: 'row', tablet: 'row', mobile: 'column' }}
      justifyContent="space-between"
      alignItems={{
        desktop: 'center',
        tablet: 'center',
        mobile: 'flex-start'
      }}
      padding="medium"
      paddingRight={{
        desktop: 'none',
        tablet: 'none',
        mobile: 'medium'
      }}
      tone="transparent"
      zIndex={1000}
      spacing="large"
    >
      <Row
        paddingTop="xsmall"
        paddingLeft="xxsmall"
        tone="transparent"
        alignItems="center"
        spacing="medium"
      >
        {shouldShowActions ? (
          <>
            <FilterLink onPressLink={openFilterModal} />

            <ApplicationsFilterModal
              visible={isFilterModalOpen}
              jobId={jobId}
              onRequestClose={closeFilterModal}
            />
          </>
        ) : null}

        {shouldShowFilterStatus ? (
          <FilteredStatusPill
            onPressClearFilters={clearFilters}
            filteredCount={selectedFilters.length}
          />
        ) : null}
      </Row>

      {shouldShowActions ? (
        <Row
          paddingHorizontal={{
            desktop: 'none',
            tablet: 'none',
            mobile: 'xxsmall'
          }}
          tone="transparent"
          alignItems="center"
        >
          <SortDropdown
            dropdownOptions={sortDropdownOptions}
            selectedOption={selectedSort}
            onClickDropdown={onPressSortDropdown}
            onClickOption={onPressSortOption}
          />
        </Row>
      ) : null}
    </Stack>
  );
};

const FilterLink = ({ onPressLink }: { onPressLink: () => void }) => {
  const { t } = useTranslation();

  return (
    <Link
      icon={<FilterIcon variant="outline" size="large" />}
      variant="secondary"
      size="standard"
      onPress={() => onPressLink()}
      testID="applications-filter-filter-link"
    >
      {t('applications.applicationsFilter.action.filter')}
    </Link>
  );
};
