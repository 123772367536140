import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-native';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import {
  trackMaxLengthValidationError,
  trackMinLengthValidationError
} from '@common/components/PostJobForm/components/JobDescription/hooks/jobDescriptionActions';
import { useLimitJobRolesEnabled } from '@common/hooks/featureToggles';
import {
  useGetSourceParams,
  useSearchQueryParam
} from '@common/hooks/useSearchQueryParam';
import { useUpdateSourceQuery } from '@common/hooks/useUpdateSourceQuery';
import { User } from '@common/types';
import { SupportLevel } from '@seek/je-shared-data/lib/types/brand';
import { CompleteData, InvalidDescriptionData } from '@src/caja';
import { IncompleteBusinessFormData } from '@src/caja/Form/forms/businessForm/types';
import { IncompleteInfoFormData } from '@src/caja/Form/forms/infoForm/types';
import { IncompleteJobRoleFormData } from '@src/caja/Form/forms/jobRoleForm/types';
import { CompleteRegisterFormData } from '@src/caja/Form/forms/registerForm/types';
import { CompleteVerifyFormData } from '@src/caja/Form/forms/verifyEmailForm/types';
import CajaUnauthorisedFlow from '@src/caja/flows/unauthorised/CajaUnauthorisedFlow';
import { minMax } from '@src/description';
import { getUser } from '@store/auth';
import { ErrorResponse } from '@store/auth/types';
import { getDraftBusiness, getDraftJob, saveDraftForm } from '@store/draft';
import { BUSINESS_DRAFT_FORM, JOB_DRAFT_FORM } from '@store/draft/constants';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Stack } from '@components/Stack';
import { useBusiness, useJob, useRegistration } from './hooks';
import { useSignUp } from './useSignUp';

export const SignUp = () => {
  useUpdateSourceQuery();
  const sourceParams = useGetSourceParams();
  const { pathname } = useLocation();
  const {
    supportTier,
    countryCode: siteCountryCode,
    brandNameText
  } = useAppSelector(getLocalisation);
  const { currentUser } = useAppSelector(getUser);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { getQueryParam } = useSearchQueryParam();
  const showJobRoleForm = useLimitJobRolesEnabled();
  const evToken = getQueryParam('ev') as string;
  const { register, sendVerificationEmail, verifyEmail } = useRegistration();
  const { createBusiness } = useBusiness();
  const { createJob } = useJob(t);
  const { redirectToPostJobPage } = useSignUp();
  const draftJob = useAppSelector(getDraftJob);
  const draftBusiness = useAppSelector(getDraftBusiness);

  function handleJobChange(
    data: IncompleteInfoFormData & IncompleteJobRoleFormData
  ) {
    dispatch(saveDraftForm(JOB_DRAFT_FORM, data));
  }

  function handlebusinessChange(data: IncompleteBusinessFormData) {
    dispatch(saveDraftForm(BUSINESS_DRAFT_FORM, data));
  }

  async function handleRegisterSubmit(
    data: CompleteRegisterFormData
  ): Promise<{ isSuccessful: boolean; error?: ErrorResponse }> {
    const response = await register({
      registerData: data,
      sourceParams
    });

    if (response.isSuccessful) {
      await sendVerificationEmail({ isResend: false });
    }

    return response;
  }

  async function handleVerifySubmit(data: CompleteVerifyFormData) {
    const { isSuccessful } = await verifyEmail({
      verificationCode: data.emailVerificationCode
    });
    return isSuccessful;
  }

  async function handleResendVerificationEmailSubmit() {
    await sendVerificationEmail({ isResend: true });
  }

  async function handleVerifySuccessSubmit(data: CompleteData) {
    const { business, job } = data;
    const user = currentUser as User;

    // Create business
    const { isSuccessful: isCreateBusinessSuccessful, data: businessData } =
      await createBusiness({ business, sourceParams });
    if (!isCreateBusinessSuccessful) {
      redirectToPostJobPage();
      return;
    }

    // Create job
    const businessWithIdAndToken = {
      ...business,
      id: businessData.businessId,
      token: businessData.token
    };
    const { isSuccessful: isCreateJobSuccessful } = await createJob({
      user,
      job,
      business: businessWithIdAndToken,
      sourceParams
    });
    if (!isCreateJobSuccessful) {
      redirectToPostJobPage();
      return;
    }
  }

  function handleInvalidDescription(data: InvalidDescriptionData) {
    if (data.reason === 'MIN_LENGTH') {
      dispatch(trackMinLengthValidationError());
    } else if (data.reason === 'MAX_LENGTH') {
      dispatch(trackMaxLengthValidationError());
    }
  }

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('postJobPage.title')} />
      <CajaUnauthorisedFlow
        allowBusinessAndEmailChange={false}
        brandNameText={brandNameText as string}
        currentPath={pathname}
        defaultJobValues={{
          ...draftJob
        }}
        defaultBusinessValues={{
          country: siteCountryCode,
          ...draftBusiness
        }}
        defaultRegisterValues={{}}
        disablePosting={supportTier === SupportLevel.TIER_3}
        infoFormOptions={{
          jobDescriptionLength: minMax(evToken),
          submitButtonLabel: t('common.action.next'),
          onInvalidDescription: handleInvalidDescription
        }}
        onJobChange={handleJobChange}
        onBusinessChange={handlebusinessChange}
        onRegisterSubmit={handleRegisterSubmit}
        onVerifySubmit={handleVerifySubmit}
        onResendVerificationEmailSubmit={handleResendVerificationEmailSubmit}
        onVerifySuccessSubmit={handleVerifySuccessSubmit}
        showJobRoleForm={showJobRoleForm}
        showProgress={true}
        t={t}
        userEmail={currentUser?.email}
        userEmailVerified={currentUser?.emailVerified}
      />
    </Stack>
  );
};
