import React, { useContext } from 'react';
import { JobIndustry as InnerField } from '@src/joraEmployer/pages/job/select-job-role/components/JobRoleForm/components/JobIndustry';
import { FieldWrapper } from '../FieldWrapper';
import { FormContext } from '../FormContext';
import { jobIndustryRules } from '../rules';

export default function JobTitle() {
  const { t } = useContext(FormContext);
  return (
    <FieldWrapper<string>
      name="jobIndustry"
      rules={jobIndustryRules(t)}
      render={(props) => <InnerField {...props} />}
    />
  );
}
