import React from 'react';
import { FlatList } from 'react-native';
import { NPS } from '@common/components/NPS';
import { isStatusFiltered } from '@common/helpers/applications';
import { useUpdateNote } from '@common/hooks/application/notes/useUpdateNote';
import { Application } from '@common/types';
import { Stack } from '@components/Stack';
import { ApplicationCard } from '../ApplicationCard';
import { ApplicationContext } from './ApplicationContext';
import { ApplicantStatusChangeInfo } from './components/ApplicantStatusChangeInfo';
import { useApplicationList, useNps } from './useApplicationList';

type ApplicationListProps = {
  jobId: string;
  applications: Application[];
  testID?: string;
};

export const ApplicationList = (props: ApplicationListProps) => {
  const { jobId, applications, testID } = props;
  const { filters, jobCountryCode } = useApplicationList(jobId);
  const { shouldRenderNPS, npsTrackingData } = useNps(jobId);
  const updateNote = useUpdateNote();

  return (
    <ApplicationContext.Provider value={{ updateNote }}>
      <Stack tone="transparent" testID={testID}>
        <FlatList
          data={applications}
          renderItem={({ item: application, index }) => {
            return (
              <Stack key={`${application.id}-card`} tone="transparent">
                {isStatusFiltered(filters, application.status) ? (
                  <ApplicationCard
                    jobId={jobId}
                    jobCountryCode={jobCountryCode}
                    application={application}
                  />
                ) : (
                  <ApplicantStatusChangeInfo
                    testID={`${application.id}-moved-to-status`}
                    applicantName={application.name}
                    applicantStatus={application.status}
                  />
                )}

                {shouldRenderNPS(index) ? (
                  <NPS trackingData={npsTrackingData} />
                ) : null}
              </Stack>
            );
          }}
        />
      </Stack>
    </ApplicationContext.Provider>
  );
};
