import { useTranslation } from 'react-i18next';
import { localisedFormatDate } from '@common/helpers/dateTime';
import { getApplications } from '@store/entities/applications/selectors';
import { useAppSelector } from '@store/hooks';
import { EmptyApplicationsType } from './EmptyApplicationsType';
import {
  CurrentSelectedTab,
  useEmptyApplicationsInformationType
} from './useEmptyApplicationsInformationType';

type UseEmptyApplicationsInformationCard = {
  shouldRenderEmptyInformationCard: boolean;
  emptyApplicationsInformationCopy?: {
    description: string;
    subtitle?: string;
  };
};

export function useEmptyApplicationsInformationCard(
  jobId: string,
  currentSelectedTab: CurrentSelectedTab
): UseEmptyApplicationsInformationCard {
  const { t } = useTranslation();
  const emptyApplicationsType = useEmptyApplicationsInformationType(
    jobId,
    currentSelectedTab
  );
  const { applicationsAvailability } =
    useAppSelector(getApplications(jobId)) || {};

  const unavailableAtDateCopy = localisedFormatDate(
    t,
    'dd MMM yyyy',
    applicationsAvailability?.unavailableAt
  );

  const emptyTypeTranslations = {
    [EmptyApplicationsType.EXPIRED_APPLICATIONS]: {
      description: t('applications.postExpiryWarning', {
        date: unavailableAtDateCopy
      })
    },
    [EmptyApplicationsType.INACTIVE_JOB_WITH_NO_APPLICATIONS]: {
      description: t('applications.description.noApplicantsThisTime')
    },
    [EmptyApplicationsType.NEW_ACTIVE_JOB_WITH_NO_APPLICATIONS]: {
      description: t('applications.description.noApplicantsYet'),
      subtitle: t('applications.description.waitForApplicants')
    },
    [EmptyApplicationsType.OLD_ACTIVE_JOB_WITH_NO_APPLICATIONS]: {
      description: t('applications.description.noApplicantsYet'),
      subtitle: t('applications.description.attractMoreApplicants')
    },
    [EmptyApplicationsType.NO_FILTERED_APPLICATIONS_ON_FILTER]: {
      description: t('applications.description.filterZeroResult')
    },
    [EmptyApplicationsType.NO_FILTERED_APPLICATIONS_ON_DEFAULT_FILTER]: {
      description: t('applications.description.defaultFilterZeroResult')
    },
    [EmptyApplicationsType.ACTIVE_JOB_WITH_NO_MATCHED_APPLICATIONS]: {
      description: t('applications.description.noRightApplicantsYet'),
      subtitle: t('applications.subtitle.editThisJobForSuitableApplicant')
    },
    [EmptyApplicationsType.ACTIVE_JOB_WITH_NO_OTHER_APPLICATIONS_FOR_UPGRADABLE_JOB]:
      {
        description: t('applications.description.noOtherApplicationHits'),
        subtitle: t('applications.subtitle.upgradeThisJob')
      },
    [EmptyApplicationsType.ACTIVE_JOB_WITH_NO_OTHER_APPLICATIONS]: {
      description: t('applications.description.noOtherApplicationHits'),
      subtitle: t('applications.subtitle.editThisJob')
    },
    [EmptyApplicationsType.INACTIVE_JOB_WITH_NO_MATCHED_APPLICATIONS]: {
      description: t('applications.description.noApplicantsThisTime')
    },
    [EmptyApplicationsType.INACTIVE_JOB_WITH_NO_OTHER_APPLICATIONS]: {
      description: t('applications.description.noApplicantsThisTime')
    }
  };

  return {
    shouldRenderEmptyInformationCard: !!emptyApplicationsType,
    emptyApplicationsInformationCopy:
      emptyApplicationsType && emptyTypeTranslations[emptyApplicationsType]
  };
}
