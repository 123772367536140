import React, { forwardRef, useRef, useState } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import {
  RichEditor,
  RichToolbar,
  actions
} from 'react-native-pell-rich-editor';
import { useRichTextTransform } from '@components/FormElements/RichTextInput/useRichTextTransform';
import { Divider } from '../../Divider';
import {
  UseRichTextInputStyleProps,
  useRichTextInputStyle
} from './useRichTextInputStyle';

export type RichTextInputProps = Omit<
  UseRichTextInputStyleProps,
  'isFocused'
> & {
  value?: string;
  footer?: (footerProps: RichTextInputFooterProps) => React.ReactNode;
  onBlur?: () => void;
  onChange: (text: string) => void;
  testID?: string;
};

type RichTextInputFooterProps = Pick<RichTextInputProps, 'tone'> & {
  isFocused: boolean;
};

export const RichTextInput = forwardRef<RichEditor, RichTextInputProps>(
  (props, ref) => {
    const {
      maxHeight,
      minHeight,
      tone,
      value,
      footer,
      onBlur,
      onChange,
      testID
    } = props;
    const hasContentRef = useRef(false);
    const scrollViewRef = useRef<ScrollView>(null);
    const richTextRef = useRef<RichEditor | null>();
    const [isFocused, setFocused] = useState(false);
    const {
      adjustEditorHeight,
      containerStyle,
      innerContainerStyle,
      editorStyle,
      editorSupportStyle,
      toolbarStyle,
      toolbarIconStyle: {
        iconSize,
        iconTint,
        selectedIconTint,
        disabledIconTint
      }
    } = useRichTextInputStyle({ isFocused, maxHeight, minHeight, tone });
    const { transform } = useRichTextTransform();

    if (!hasContentRef.current && value) {
      hasContentRef.current = true;
      richTextRef.current?.setContentHTML(value);
    }

    const onValueChanged = (text: string) => onChange(transform(text));

    const assignRichTextEditorRef = (richEditor: RichEditor) => {
      richTextRef.current = richEditor;
      if (typeof ref === 'function') {
        ref(richEditor);
      } else if (ref) {
        ref.current = richEditor;
      }
    };

    return (
      <View style={containerStyle}>
        <RichToolbar
          actions={[
            actions.setBold,
            actions.insertBulletsList,
            actions.insertOrderedList
          ]}
          editor={richTextRef}
          iconSize={iconSize}
          iconTint={iconTint}
          selectedIconTint={selectedIconTint}
          disabledIconTint={disabledIconTint}
          style={toolbarStyle}
        />
        <ScrollView
          testID={testID}
          ref={scrollViewRef}
          style={{
            height: editorSupportStyle.currentHeight,
            marginBottom: editorSupportStyle.marginBottom
          }}
        >
          <RichEditor
            initialContentHTML={value}
            initialHeight={editorSupportStyle.minHeight}
            editorStyle={editorStyle}
            style={innerContainerStyle}
            ref={assignRichTextEditorRef}
            onHeightChange={adjustEditorHeight}
            onCursorPosition={(offsetY) => {
              if (Platform.OS !== 'web') {
                scrollViewRef.current?.scrollTo({
                  y: offsetY - editorSupportStyle.lineHeight,
                  animated: true
                });
              }
            }}
            onChange={onValueChanged}
            onFocus={() => setFocused(true)}
            onBlur={() =>
              setFocused(() => {
                onBlur?.();
                return false;
              })
            }
          />
        </ScrollView>
        {footer ? (
          <>
            <Divider tone="neutralDark" />
            {footer({ isFocused, tone })}
          </>
        ) : null}
      </View>
    );
  }
);
