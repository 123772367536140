import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@components/FormElements/Dropdown';
import { Row } from '@components/Row';
import { Text } from '@components/Text';

type Props = {
  onClickDropdown: () => void;
  onClickOption: (selectedValue) => void;
  selectedOption: string;
  dropdownOptions: Array<{ label: string; value: string }>;
};

export const SortDropdown = (props: Props) => {
  const { t } = useTranslation();
  const { onClickDropdown, onClickOption, selectedOption, dropdownOptions } =
    props;

  return (
    <Row
      tone="transparent"
      alignItems="center"
      spacing="xsmall"
      flexShrink={1}
      flexWrap="wrap"
      justifyContent="flex-end"
    >
      <Text>{t('applications.sortingDropdown.sortBy')}</Text>
      <Dropdown
        width="small"
        testID="sorting-dropdown"
        selectedItem={selectedOption}
        onChange={onClickOption}
        onDropdownOpen={onClickDropdown}
        items={dropdownOptions}
      />
    </Row>
  );
};
