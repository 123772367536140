import { DEFAULT_STEP_MAP } from '../steps';
import type { CajaProps, HydratedCajaProps } from '../types';

export function hydrateDefaultProps(props: CajaProps): HydratedCajaProps {
  return {
    allowBusinessAndEmailChange: props.allowBusinessAndEmailChange ?? false,
    brandNameText: props.brandNameText,
    currentPath: props.currentPath,
    defaultJobValues: props.defaultJobValues ?? {},
    defaultBusinessValues: props.defaultBusinessValues ?? {},
    defaultRegisterValues: props.defaultRegisterValues ?? {},
    infoFormOptions: props.infoFormOptions ?? {},
    onJobChange: props.onJobChange ?? (() => {}),
    onBusinessChange: props.onBusinessChange ?? (() => {}),
    onSubmit: props.onSubmit ?? (() => {}),
    onRegisterSubmit:
      props.onRegisterSubmit ??
      (() => Promise.resolve({ isSuccessful: false })),
    onVerifySubmit: props.onVerifySubmit ?? (() => Promise.resolve()),
    onVerifySuccessSubmit:
      props.onVerifySuccessSubmit ?? (() => Promise.resolve()),
    onResendVerificationEmailSubmit:
      props.onResendVerificationEmailSubmit ?? (() => Promise.resolve()),
    showJobRoleForm: props.showJobRoleForm ?? false,
    showProgress: props.showProgress ?? false,
    stepMap: props.stepMap ?? DEFAULT_STEP_MAP,
    t: props.t,
    updateDebounce: props.updateDebounce ?? 200,
    userEmail: props.userEmail ?? undefined,
    userEmailVerified: props.userEmailVerified ?? undefined
  };
}
