import { useTranslation } from 'react-i18next';
import { JOB_INDUSTRY_AND_ROLE } from '@seek/je-shared-data';

export const useJobRole = (industry: string) => {
  const { t } = useTranslation();

  const jobRoleOptions =
    JOB_INDUSTRY_AND_ROLE.find(
      (option) => option.industry === industry
    )?.roles.map((role) => ({
      key: role,
      value: role,
      label: t(`job.jobRole.${industry}.${role}`)
    })) || [];

  const sortedJobRoleOptions = jobRoleOptions.sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  return { jobRoleOptions: sortedJobRoleOptions };
};
