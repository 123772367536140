import { useGetPostJobLink } from '@common/hooks/appPageLinks';
import { useRedirect } from '@common/hooks/useRedirect';

const useRedirectToUpgradeJobPage = () => {
  const { redirect } = useRedirect();

  return (jobId: string, countryCode: string) => {
    return redirect(
      `/${countryCode}/jobs/${jobId}/upgrade-job?fromPostJob=true`
    )();
  };
};

const useRedirectToPostJobPage = () => {
  const { redirect } = useRedirect();
  const postJobLink = useGetPostJobLink();

  return () => redirect(postJobLink);
};

export const useSignUp = () => {
  const redirectToUpgradeJobPage = useRedirectToUpgradeJobPage();
  const redirectToPostJobPage = useRedirectToPostJobPage();

  return {
    redirectToUpgradeJobPage,
    redirectToPostJobPage
  };
};
