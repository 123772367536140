import { useModal } from '@components/Modal';

export function useApplicationsFilterModal() {
  const { openModal, closeModal, isModalOpen } = useModal();

  return {
    openFilterModal: openModal,
    closeFilterModal: closeModal,
    isFilterModalOpen: isModalOpen
  };
}
