import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { SingleSelect } from '@common/components/FormElements';
import { Box } from '@components/Box';
import { Field } from '@components/FormElements';
import { useJobRole } from './hooks/useJobRole';

type JobRoleProps = {
  value: string;
  onChange: () => void;
  industry: string;
  error?: string;
};

export const JobRole = ({ error, onChange, value, industry }: JobRoleProps) => {
  const { t } = useTranslation();
  const { jobRoleOptions } = useJobRole(industry);

  return (
    <Field
      label={t('jobRoleForm.fieldLabel.jobRole')}
      message={
        error && (
          <Box marginTop="xsmall">
            <ErrorMessage>{error}</ErrorMessage>
          </Box>
        )
      }
    >
      <Box marginTop="xsmall">
        <SingleSelect
          testID="job-role-form"
          onChange={onChange}
          options={jobRoleOptions}
          value={value}
        />
      </Box>
    </Field>
  );
};
