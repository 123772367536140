import React from 'react';
import { useTranslation } from 'react-i18next';
import { openExternalURL } from '@common/helpers/externalConnection';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Button } from '@components/Button';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { useTrackLink } from './hooks/useTrackLink';

export const RoleNotAvailable = () => {
  const { t } = useTranslation();
  const { copyrightText, mainEmployerBrandLink, mainEmployerBrandNameText } =
    useAppSelector(getLocalisation);
  const { trackLinkoutClick } = useTrackLink();

  const handleButtonClick = () => {
    trackLinkoutClick();
    openExternalURL(mainEmployerBrandLink);
  };

  return (
    <Card>
      <CardContent>
        <Stack spacing="medium" paddingTop="medium">
          <Heading level="h2">
            {t('selectJobRolePage.roleNotAvailableCard.heading', {
              mainEmployerBrandNameText
            })}
          </Heading>

          <Button variant="secondary" onPress={handleButtonClick}>
            {t('selectJobRolePage.action.visit', { copyrightText })}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};
