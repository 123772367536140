import React, { useCallback } from 'react';
import { Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { normaliseExtractedText } from '@common/helpers/textHelper';
import { Box } from '@components/Box';
import { Field } from '@components/FormElements';
import { RichTextInput } from '@components/FormElements/RichTextInput';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { JobDescriptionCalloutDetails } from './components/JobDescriptionCalloutDetails';

type JobDescriptionProps = {
  value: string;
  onChange: () => void;
  onBlur: () => void;
  error?: string;
};

export const JobDescription = ({
  error,
  onChange,
  onBlur,
  value
}: JobDescriptionProps) => {
  const { t } = useTranslation();

  const Footer = useCallback(() => {
    const normalisedExtractedText = normaliseExtractedText(value || '');

    return (
      <Box
        alignItems="flex-end"
        margin="small"
        testID="job-form-jobDescription-footer"
      >
        <Text size="small" tone="neutralLight">
          {t('postJobForm.jobDescription.characters', {
            count: normalisedExtractedText.length
          })}
        </Text>
      </Box>
    );
  }, [t, value]);

  return (
    <Stack spacing="small">
      <Field
        label={t('postJobForm.fieldLabel.jobDescription')}
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Box>
          <RichTextInput
            maxHeight={Platform.OS === 'web' ? 'xlarge' : undefined}
            tone={error ? 'danger' : 'neutral'}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            footer={Footer}
          />
        </Box>
      </Field>

      <JobDescriptionCalloutDetails />
    </Stack>
  );
};
