import { createContext } from 'react';
import type { Control, FieldErrorsImpl } from 'react-hook-form';
import { T } from '../../types';
import { IncompleteJobRoleFormData } from './types';

interface FormContextProps {
  control: Control<IncompleteJobRoleFormData>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  t: T;
}

export const FormContext = createContext<FormContextProps>({
  control: {} as Control<IncompleteJobRoleFormData>,
  errors: {},
  t: () => ''
});
